<template>
  <div v-if="lesson" style="max-width: 800px" class="mx-auto">
    <b-row>
      <b-col cols="8">
        <div class="mr-2">
          <div class="spacecard" v-if="material" >
            <MaterialTeaser :material="material" :editable="false"/>
          </div>
        </div>
      </b-col>
      <b-col cols="4">
        <div>
          <a class="btn btn-themed btn-white w-100"
             @click="$bvModal.show('selectmaterialmodal')">
            {{ !material ? $t('add_program') : $t('change_program') }}
          </a>
        </div>
        <div class="mt-3">
          <a class="btn btn-themed h-100 w-100" v-if="lesson && material" @click="start">
            {{ $t('start') }}
          </a>
        </div>
        <div class="mt-3">
          <a class="btn btn-themed btn-white h-100 w-100" @click="quickcall">
            {{ $t('quick_call') }}
          </a>
        </div>
      </b-col>
    </b-row>
    <SelectMaterial @selected="materialSelected"/>
    <QuickCallModal/>
  </div>
</template>

<script>

import SelectMaterial from '@/views/parts/class/SelectMaterial'
import MaterialTeaser from '@/views/teasers/MaterialTeaser'
import {ManagerService} from "../../../services/api.service"
import QuickCallModal from '@/views/pages/instructor/schedule/QuickCallModal'

export default {
  name: "ManagerPage",
  components: {
    SelectMaterial,
    MaterialTeaser,
    QuickCallModal
  },
  data() {
    return {
      lesson: null,
      material: null
    }
  },
  mounted() {
    this.getLesson()
  },
  methods: {
    materialSelected(material) {
      if(!this.lesson) return
      this.material = material
      console.log(this.lesson)
      this.saveLesson(this.lesson.id)
    },
    async getLesson() {
      let res = await ManagerService.getLesson()
      if(res && res.data) {
        this.lesson = res.data.data
        if(this.lesson.material) {
          this.material = this.lesson.material
        }
      }
    },
    async saveLesson(lessonId) {
      if(!this.material) return
      let res = await ManagerService.saveLesson(lessonId, {
        material_id: this.material.id
      })
      return res.data
    },
    async start() {
      let res = await this.saveLesson(this.lesson.id)
      if(res) {
        this.$router.push({
          name: 'Lesson', params: {
            id: this.lesson.id,
            code: res.data.code
          }
        })  
      }
    },
    quickcall() {
      this.$bvModal.show('quick-call-modal')
    },
  }
}
</script>

<style scoped>

</style>
